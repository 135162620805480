<template>
    <div v-if="isDiscountVisible && discountPercentage" class="edeka-tile-discount">-{{ discountPercentage }}%</div>
</template>

<script>
import price from '@/mixins/price';

export default {
    name: 'EdekaTileDiscount',
    mixins: [price]
};
</script>

<style scoped lang="scss"></style>

<template>
    <Link
        class="grid-tile edeka-grid-tile"
        :link="block.link"
        v-ripple
        :class="{ 'grid-tile--content': hasMiddleTitle, 'grid-tile--dimmed': block.dimmed }"
    >
        <div class="grid-tile__media-wrapper edeka-grid-tile__media-wrapper">
            <ImageFluid
                :src="block.imageUrl"
                :alt="block.text"
                class="grid-tile__media-image"
                :eager="highest"
                width="750"
                height="550"
            />

            <div v-if="block.soldOut" class="grid-tile__sold-out">
                <div class="grid-tile__sold-out-text">{{ $t('ProductDetails.productSelect.soldOut') }}</div>
            </div>

            <div class="grid-tile__media-top edeka-tile__media-top">
                <!--gradient top-->
                <div v-if="banner || productPrice" class="edeka-grid-tile__banner-wrapper">
                    <div v-if="banner" class="grid-tile__banner edeka-grid-tile__banner">
                        {{ banner }}
                    </div>

                    <EdekaTileDiscount v-if="productPrice" :price="block.price" class="edeka-grid-tile__discount" />
                </div>

                <!--TODO: take block.favorite when ready on backend-->
                <Favorite v-if="block.id" :product="block" class="grid-tile__favorite grid-tile__media-element" />
            </div>

            <div class="grid-tile__media-bottom">
                <!--gradient bottom-->
                <Price
                    v-if="productPrice"
                    hide-discount
                    :price="block.price"
                    small-prefix
                    class="grid-tile__price edeka-grid-tile__price grid-tile__media-element"
                />

                <IconSvg
                    v-if="block.directBookingAvailable"
                    filepath="calendar.svg"
                    class="grid-tile__direct-booking grid-tile__media-element"
                />
            </div>
        </div>

        <div v-if="showHeader || block.text" class="grid-tile__header edeka-grid-tile__header">
            <i v-if="hasMiddleTitle" class="edeka-grid-tile__content-icon"> </i>

            <div v-if="title" class="grid-tile__title edeka-grid-tile__title text-truncate" :title="title">
                {{ title }}
            </div>

            <div v-if="showSubtitle" class="grid-tile__subtitle">
                <Classification
                    v-if="block.hotelClassification"
                    :hotelClassification="block.hotelClassification"
                    inline
                />

                <span v-else-if="block.subtitle">{{ block.subtitle }}</span>

                <div v-if="showDuration" v-html="duration" class="grid-tile__duration ml-auto"></div>
            </div>

            <div v-if="block.text" class="edeka-grid-tile__text" :title="block.text">
                <span class="grid-tile__clamp">{{ block.text }}</span>
            </div>
        </div>
    </Link>
</template>

<script>
import GridTile from '@/components/grid/GridTile';
import EdekaTileDiscount from '@/themes/edeka/components/grid/EdekaTileDiscount';

export default {
    name: 'EdekaGridTile',
    components: { EdekaTileDiscount },
    extends: GridTile,

    data() {
        return {};
    },

    props: {},

    computed: {
        showSubtitle() {
            return (
                !this.hasMiddleTitle &&
                (this.block.subtitle ||
                    this.$getSafe(this.block, 'hotelClassification.classificationValue') ||
                    this.showDuration)
            );
        }
    }
};
</script>

<style lang="scss">
@use '@/themes/edeka/styles/variables';
@use '@/themes/edeka/styles/mixins';

.edeka-grid-tile {
    $self: &;
    background-image: linear-gradient(0deg, transparent 5px, rgb(255, 255, 255) 5px);

    &__media-wrapper {
        background-color: hsl(0, 0%, 92%);
    }

    &__header.grid-tile__header {
        flex: 0 0 160px;
        position: relative;
        padding: 12px 16px;

        &::before,
        &::after {
            display: block;
            content: '';
            width: 100%;
            height: 5px;
            background-repeat: repeat-x;
            color: hsl(0, 0%, 100%);
            background-image: url('~@/themes/edeka/assets/icons/grid-tile-border.svg');
            position: absolute;
            left: 0;
            z-index: 1;
        }

        &::before {
            top: -4px;
        }

        &::after {
            bottom: 0;
            transform: rotate(180deg);
        }
    }

    &__title.grid-tile__title {
        font-family: variables.$font-family-headline;
        color: variables.$body-color !important;
        font-size: 18px;
    }

    .grid-tile__subtitle {
        margin-top: 5px;
    }

    &__banner-wrapper {
        margin-left: 16px;
    }

    &__discount {
        margin-top: 2px;
        background: rgb(226, 37, 26);
        color: white;
        font-family: variables.$font-family-headline;
        padding: 6px 8px;
        height: 40px;
        font-size: 26px;
        line-height: 1;
        border-bottom-right-radius: 5px;
        text-align: center;
        width: 80px;
    }

    &__banner.grid-tile__banner {
        margin-top: 2px;
        font-family: variables.$font-family-headline;
        background-color: variables.$secondary !important;
        color: variables.$body-color;
        width: 80px;
        font-size: 10px;
    }

    &__price.grid-tile__price {
        margin-left: auto !important;
        font-family: variables.$font-family-headline;
        font-weight: normal;

        .price-values {
            line-height: 1;
        }

        .price-value {
            font-size: 36px;
        }

        .help-text {
            text-align: right;
            line-height: 1;
            margin-top: 3px;
        }
    }

    .grid-tile__media-element {
        margin: 16px;
    }

    .grid-tile__direct-booking {
        order: -1;
    }

    &__text {
        font-size: 14px;
        line-height: 1.2;
        color: rgb(153, 153, 153);
        margin-top: 10px;

        &:first-child {
            @include mixins.title-font;
            color: variables.$body-color;
            font-size: 24px;
            margin-top: 0;
        }

        .grid-tile__clamp {
            line-clamp: 4;
            -webkit-line-clamp: 4;
        }
    }

    &.grid-tile--content {
        #{$self}__header {
            display: flex;
            &::before {
                content: none;
            }
        }

        #{$self}__text {
            @include mixins.title-font;
            margin: auto;
            font-size: 24px;
            color: variables.$body-color;
            text-align: center;

            .grid-tile__clamp {
                line-clamp: unset;
                -webkit-line-clamp: unset;
            }
        }

        #{$self}__content-icon {
            @include mixins.round-button;
            font-size: 60px;
            position: absolute;
            border-radius: 50%;
            left: 50%;
            top: -2px;
            transform: translate(-50%, -50%);
            z-index: 2;
        }
    }
}
</style>
